.tr-header {
  min-width: 10rem;
}
@media (min-width: 999px) {
  .tr-header {
    min-width: 20rem;
  }
}
@media (min-width: 1024px) {
  .tr-header {
    min-width: 30rem;
  }
}
