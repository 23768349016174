@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;1,200;1,300&display=swap');

body {
  margin: 0;
  font-weight: 200;
  font-family: 'Prompt', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f7fa !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Prompt', sans-serif !important;
}
.fw-bold{
  font-weight: 500 !important;
}
.bg-nav {
  background-color: #ffffffe8 !important;
  border-bottom: 1px solid #e3e3e3;
}
